export const products = [
  {
    id: "1",
    category: "Lathe Machine",
    name: "Conpulley/V-Belt Lathe Machine",
    description:
      "We are the prominent manufacturer and supplier of a wide range of Conpulley / V Belt Type Lathe Machine. These heavy duty lathe machines are designed using high grade required material and advanced technology. Our offered lathe machines are examined by our professionals on industry developed parameters. Heavy duty lathe machines offered by us are also treasured by our clients for their countless quality aspects.",
    img: "https://navashuindia.com/products/1/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Bed length Ft.": [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 40],
      "Width of Bed": [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 48],
      "Height of Centre": [
        10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 34, 38, 46,
      ],
      "Spindle Bore": ["2½", 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      "Swing in Gap": [
        32, 36, 42, 52, 55, 60, 68, 72, 76, 80, 84, 92, 100, 116,
      ],
      "Swing Over Bed": [
        20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 68, 76, 92,
      ],
      "No. of Spindle Speed": [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      "Admit Between Centre": [
        30, 38, 54, 72, 96, 120, 138, 150, 174, 198, 222, 294, 354, 402,
      ],
      "Leading Screw Dia": [
        "1¼",
        "1½",
        "1½",
        "1¾",
        2,
        2,
        "2½",
        "2½",
        "2¾",
        "2¾",
        3,
        3,
        "3½",
        "4½",
      ],
      "H.P. Required": [2, 2, 2, 3, 3, 3, 5, 5, "7½", 10, 15, 20, 25, 25],
    },
  },
  {
    id: "2",
    category: "Lathe Machine",
    name: "All Geared Machine",
    description:
      "In order to provide the finest All Geared Machine to our clients, we engage in the utilization of the highest grade of raw materials and modern machines, as per the set industry norms and guidelines. Because of this, the product's performance, precision, strength and service life is widely acclaimed and asked for. In addition, the offered range is known to be marked at the most reasonable rate possible.",
    img: "https://navashuindia.com/products/1/3.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Bed length Ft.": [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 40],
      "Width of Bed": [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 48],
      "Height of Centre": [
        10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 34, 38, 46,
      ],
      "Spindle Bore": ["2½", 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      "Swing in Gap": [
        32, 36, 42, 52, 55, 60, 68, 72, 76, 80, 84, 92, 100, 116,
      ],
      "Swing Over Bed": [
        20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 68, 76, 92,
      ],
      "No. of Spindle Speed": [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      "Admit Between Centre": [
        30, 38, 54, 72, 96, 120, 138, 150, 174, 198, 222, 294, 354, 402,
      ],
      "Leading Screw Dia": [
        "1¼",
        "1½",
        "1½",
        "1¾",
        2,
        2,
        "2½",
        "2½",
        "2¾",
        "2¾",
        3,
        3,
        "3½",
        "4½",
      ],
      "H.P. Required": [2, 2, 2, 3, 3, 3, 5, 5, "7½", 10, 15, 20, 25, 25],
    },
  },
  {
    id: "3",
    category: "Lathe Machine",
    name: "Roller Regrooving Lathe Machine",
    description:
      "The Roller Regrooving Lathe Machine manufactured by 'Nav Ashu Machine Tools' is widely used in sugar mill, rolling mill, paper mill, rubber rolls etc.",
    img: "https://navashuindia.com/products/1/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Bed length Ft.": [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 40],
      "Width of Bed": [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 48],
      "Height of Centre": [
        10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 34, 38, 46,
      ],
      "Spindle Bore": ["2½", 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      "Swing in Gap": [
        32, 36, 42, 52, 55, 60, 68, 72, 76, 80, 84, 92, 100, 116,
      ],
      "Swing Over Bed": [
        20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 68, 76, 92,
      ],
      "No. of Spindle Speed": [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      "Admit Between Centre": [
        30, 38, 54, 72, 96, 120, 138, 150, 174, 198, 222, 294, 354, 402,
      ],
      "Leading Screw Dia": [
        "1¼",
        "1½",
        "1½",
        "1¾",
        2,
        2,
        "2½",
        "2½",
        "2¾",
        "2¾",
        3,
        3,
        "3½",
        "4½",
      ],
      "H.P. Required": [2, 2, 2, 3, 3, 3, 5, 5, "7½", 10, 15, 20, 25, 25],
    },
  },
  {
    id: "4",
    category: "Lathe Machine",
    name: "Roll Turning Lathe Machine",
    description:
      "As part of our range of lathe machines, we present world class Roll Turning Lathe Machines which are made from best quality materials that offer strength and durability to them. The Roll Turning Lathe Machines provided by us are widely used for proof and finish machining in rolling mills, cement plants, paper plants and heavy industries. The Roll Turning Lather machines offered by us are facilitated with power drives that enable carbide tipped tools to be used to their full capacity. Along with that, our roll turning lathe machines are provided with several carriages and special steady rests that allow matting rolls to be set up. The user friendly design of this machine has listed us among the well known roll turning lathe machine manufacturers and suppliers based in Punjab.",
    img: "https://navashuindia.com/products/1/4.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Bed length Ft.": [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 40],
      "Width of Bed": [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 48],
      "Height of Centre": [
        10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 34, 38, 46,
      ],
      "Spindle Bore": ["2½", 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      "Swing in Gap": [
        32, 36, 42, 52, 55, 60, 68, 72, 76, 80, 84, 92, 100, 116,
      ],
      "Swing Over Bed": [
        20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 68, 76, 92,
      ],
      "No. of Spindle Speed": [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      "Admit Between Centre": [
        30, 38, 54, 72, 96, 120, 138, 150, 174, 198, 222, 294, 354, 402,
      ],
      "Leading Screw Dia": [
        "1¼",
        "1½",
        "1½",
        "1¾",
        2,
        2,
        "2½",
        "2½",
        "2¾",
        "2¾",
        3,
        3,
        "3½",
        "4½",
      ],
      "H.P. Required": [2, 2, 2, 3, 3, 3, 5, 5, "7½", 10, 15, 20, 25, 25],
    },
  },
  {
    id: "5",
    category: "Lathe Machine",
    name: "Facing Lathe",
    description:
      "The Facing Lathe is suitable for high-speed steel, hard alloy and ceramic cutting tool, machining inner and outer cylindrical surface, inner and outer conical surface, groove and plane of ferrous metals,nonferrous metals and some non-metallic parts. The Facing Lathe Machine is widely used in forging unit. It can meet different quantity production requirements with high efficiency, stable and reliable precision.",
    img: "https://navashuindia.com/products/1/6.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Bed length Ft.": [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 40],
      "Width of Bed": [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 48],
      "Height of Centre": [
        10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 34, 38, 46,
      ],
      "Spindle Bore": ["2½", 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      "Swing in Gap": [
        32, 36, 42, 52, 55, 60, 68, 72, 76, 80, 84, 92, 100, 116,
      ],
      "Swing Over Bed": [
        20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 68, 76, 92,
      ],
      "No. of Spindle Speed": [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      "Admit Between Centre": [
        30, 38, 54, 72, 96, 120, 138, 150, 174, 198, 222, 294, 354, 402,
      ],
      "Leading Screw Dia": [
        "1¼",
        "1½",
        "1½",
        "1¾",
        2,
        2,
        "2½",
        "2½",
        "2¾",
        "2¾",
        3,
        3,
        "3½",
        "4½",
      ],
      "H.P. Required": [2, 2, 2, 3, 3, 3, 5, 5, "7½", 10, 15, 20, 25, 25],
    },
  },
  {
    id: "6",
    category: "Lathe Machine",
    name: "Center Lathe",
    description:
      "We are a leading manufacturer and supplier of Center Lathe machine. We are known of our quality and industry leading prices. We also supply spare parts and service for the same.",
    img: "https://navashuindia.com/products/1/7.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Bed length Ft.": [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 40],
      "Width of Bed": [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 48],
      "Height of Centre": [
        10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 34, 38, 46,
      ],
      "Spindle Bore": ["2½", 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      "Swing in Gap": [
        32, 36, 42, 52, 55, 60, 68, 72, 76, 80, 84, 92, 100, 116,
      ],
      "Swing Over Bed": [
        20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 68, 76, 92,
      ],
      "No. of Spindle Speed": [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      "Admit Between Centre": [
        30, 38, 54, 72, 96, 120, 138, 150, 174, 198, 222, 294, 354, 402,
      ],
      "Leading Screw Dia": [
        "1¼",
        "1½",
        "1½",
        "1¾",
        2,
        2,
        "2½",
        "2½",
        "2¾",
        "2¾",
        3,
        3,
        "3½",
        "4½",
      ],
      "H.P. Required": [2, 2, 2, 3, 3, 3, 5, 5, "7½", 10, 15, 20, 25, 25],
    },
  },
  {
    id: "7",
    category: "Lathe Machine",
    name: "Extra Heavy Duty Lathe Machine",
    description:
      "We are a leading manufacturer of heavy duty lathe machine in India. our heavy duty lathe machines are fabricated using quality raw material to ensure high tensile strength, high efficiency and longer service life. Heavy duty lathe machines are widely used in engineering industry, these heavy duty lathe machine are quality tested on well-defined parameters before being delivered to our clients. We manufacture heavy duty lathe machines and lathe machine parts,accessories as per your dimension and requirements.",
    img: "https://navashuindia.com/products/1/5.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Bed length Ft.": [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 40],
      "Width of Bed": [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 48],
      "Height of Centre": [
        10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 34, 38, 46,
      ],
      "Spindle Bore": ["2½", 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      "Swing in Gap": [
        32, 36, 42, 52, 55, 60, 68, 72, 76, 80, 84, 92, 100, 116,
      ],
      "Swing Over Bed": [
        20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 68, 76, 92,
      ],
      "No. of Spindle Speed": [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      "Admit Between Centre": [
        30, 38, 54, 72, 96, 120, 138, 150, 174, 198, 222, 294, 354, 402,
      ],
      "Leading Screw Dia": [
        "1¼",
        "1½",
        "1½",
        "1¾",
        2,
        2,
        "2½",
        "2½",
        "2¾",
        "2¾",
        3,
        3,
        "3½",
        "4½",
      ],
      "H.P. Required": [2, 2, 2, 3, 3, 3, 5, 5, "7½", 10, 15, 20, 25, 25],
    },
  },
  {
    id: "8",
    category: "Lathe Machine",
    name: "Vertical Turning Lathe (VTL)",
    description:
      "We are a leading manufacturer of Vertical Turning Lathe Machine in india. We manufacturer and export all kind of vertical turning lathe machines, which is manufactured using premium quality raw material. The range offered by us includes cnc vertical turning lathe machine, vertical vtl lathe machiness, and engine vertical lathe machines. These machines are designed and developed for very tight tolerances and are known for their robust construction, compact size and simplicity. Available with us are manual vertical lathe machines and cnc vertical turning lathe machines, ranging from cnc vtl. To suit the varied needs of our clients, we provide them all types of vertical turning machine such as horizontal turning machine, vertical turning machine",
    img: "https://navashuindia.com/products/1/8.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Model: [
        "NMT-1",
        "NMT-1.5",
        "NMT-2",
        "NMT-2.5",
        "NMT-3",
        "NMT-4",
        "NMT-5",
      ],
      "Max. Turning Diameter": [1100, 1700, 2200, 2700, 3200, 4200, 5200],
      "Table Diameter": [1000, 1500, 2000, 2500, 3000, 4000, 4500],
      "Max. Working Height": [600, 1000, 1200, 1500, 1600, 1600, 1600],
      "Swivel Angle of Ram Head": [
        "±30",
        "±30",
        "±30",
        "±30",
        "±30",
        "±30",
        "±30",
      ],
      "Horizontal Travel of Side Ram Head": [
        250, 300, 400, 500, 5000, 600, 600,
      ],
      "Vertical Travel of Side Ram Head": [
        400, 700, 900, 1200, 1300, 1300, 1300,
      ],
      "No. of Table Speed": [
        "variable",
        "variable",
        "variable",
        "variable",
        "variable",
        "variable",
        "variable",
      ],
      "Range of Table Speed RPM": [
        "6-60",
        "4-40",
        "3-30",
        "2-20",
        "1.5-15",
        "1-10",
        "0.7-7",
      ],
      "No. of Feeds Vertical/Horizontal": [6, 6, 6, 6, 6, 6, 6],
      "Range of Feed Vert./Hor. (mm/min)": [
        "3-12",
        "1.5-8",
        "1.5-6",
        "0.5-6",
        "0.5-5",
        "0.5-5",
        "0.5-5",
      ],
      "Rapid Traverse of Head (mm/min)": [500, 500, 500, 500, 500, 500, 500],
      "Main Motor H.P.": [7.5, 10, 15, 20, 25, 40, 50],
      "Ram Feed Gear Box Motor (H.P.)": [3, 3, 3, 5, 7.5, 7.5, 7.5],
      "Auto Lifting of Corss Rail Motor (HP)": [3, 3, 3, 5, 5, 7.5, 7.5],
    },
  },
  {
    id: "9",
    category: "Shaper",
    name: "V-Belt Shaper Machine",
    description:
      "We manufacturer V Belt Shaping Machine to best suit the needs of our clients, our shaping machine are considered ideal for shaping various metals such as steel, aluminum, ferrous & non ferrous. These are highly acclaimed by our clients for their high performance, durability, easy operation and sturdy construction. All geared shaping machine and v belt shaping machines find application in construction, electronics, engineering and allied industries.",
    img: "https://navashuindia.com/products/2/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      size: [18, 24, 30, 36, 40],
      length_of_ram: [40, 53, 60, 67, 79],
      working_stroke: [18, 24, 30, 36, 40],
      ram_bearing_in_col: [27, 36, 38, 42, 45],
      ram_bearing_in_width: [9, 11, 12, 12, 14],
      no_of_speed: [3, 4, 4, 4, 4],
      max_dis_from_table_10_ram: [14, 17, 16, 12, 2],
      motor_recommended: [2, 3, 5, 5, 5],
    },
  },
  {
    id: "10",
    category: "Shaper",
    name: "All Geared Shaper",
    description:
      "We are a manufacturer of All Geared Shaper Machines that are available in different specifications. All Geared Shaper machines are manufactured using quality components and are considered ideal for external as well as internal gears. These gear shaping machines are facilitated with a modular concept for gear shaping and gear hobbling.",
    img: "https://navashuindia.com/products/2/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      size: [18, 24, 30, 36, 40],
      length_of_ram: [40, 53, 60, 67, 79],
      working_stroke: [18, 24, 30, 36, 40],
      ram_bearing_in_col: [27, 36, 38, 42, 45],
      ram_bearing_in_width: [9, 11, 12, 12, 14],
      no_of_speed: [3, 4, 4, 4, 4],
      max_dis_from_table_10_ram: [14, 17, 16, 12, 2],
      motor_recommended: [2, 3, 5, 5, 5],
    },
  },
  {
    id: "11",
    category: "Radial Drilling Machine",
    name: "All Geared Radial Drill",
    description:
      "We take the credit for introducing All Geared Radial Drill Machine to the esteemed clients. The unmatched quality and unsurpassable performance of the All Geared Radial Drill Machine range has attracted various national as well as international clients to place repeated orders. This machine is widely used in Railway. Our All Geared Radial Drill Machines range is available with all the advanced features ensuring easy operation.",
    img: "https://navashuindia.com/products/3/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      MM: [25, 32, 40, 50],
      "Dis. of Spindle Centre to Column": [
        "175-500",
        "200-650",
        "250925",
        "400-1100",
      ],
      "Dis. of Spindle Nose to base": [
        "125-550",
        "150-680",
        "250-950",
        "310-1125",
      ],
      "Piller Diameter": [140, 175, 200, 225],
      "Travel of Arm on Column": ["675x600", "675x800", "900x900", "1275x1175"],
    },
  },
  {
    id: "12",
    category: "Radial Drilling Machine",
    name: "Single/Double Radial Drill",
    description:
      "We are reckoned amongst the most trusted name engaged in offering a superior quality range of Single Double Radial Drill Machine. This drill machine is precisely manufactured by our vendors following high definition engineering parameters and utilizing finest quality factor inputs. Durable in nature, this machine is tested properly against different parameters in order to deliver flaw free range. Client can purchase the offered drill machine from us at economical prices.",
    img: "https://navashuindia.com/products/3/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      MM: [25, 32, 40, 50],
      "Dis. of Spindle Centre to Column": [
        "175-500",
        "200-650",
        "250925",
        "400-1100",
      ],
      "Dis. of Spindle Nose to base": [
        "125-550",
        "150-680",
        "250-950",
        "310-1125",
      ],
      "Piller Diameter": [140, 175, 200, 225],
      "Travel of Arm on Column": ["675x600", "675x800", "900x900", "1275x1175"],
    },
  },
  {
    id: "13",
    category: "Radial Drilling Machine",
    name: "Autofeed, Auto Lifting",
    description:
      "Available with us a wide array of AutoFeed, Auto Lifting Machine that is offered in diverse specifications. Our vendors adept professionals make use of finest grade raw material and latest technology in order to manufacture our offered machine in line with set market norms. In addition, this machine is meticulously inspected by our quality experts to ensure its supreme quality and high performance.",
    img: "https://navashuindia.com/products/3/3.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      MM: [25, 32, 40, 50],
      "Dis. of Spindle Centre to Column": [
        "175-500",
        "200-650",
        "250925",
        "400-1100",
      ],
      "Dis. of Spindle Nose to base": [
        "125-550",
        "150-680",
        "250-950",
        "310-1125",
      ],
      "Piller Diameter": [140, 175, 200, 225],
      "Travel of Arm on Column": ["675x600", "675x800", "900x900", "1275x1175"],
    },
  },
  {
    id: "14",
    category: "Planner",
    name: "Planner Machine",
    description:
      "We are offering a vast collection of Planner Machine to our valuable clients that are widely demanded in the market for its unique features. To stand this machine perfect on international quality standards, it is tested by our quality controllers based on varied quality aspects with the help of latest tools and technology. Moreover, clients can avail this machine from us at market leading prices.",
    img: "https://navashuindia.com/products/4/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Particulars: [
        "4½ Ft. - 1375 MM",
        "6 Ft. - 1830 MM",
        "6 Ft. - 1830 MM",
        "8 Ft. - 2240 MM",
        "10 Ft. - 3050 MM",
        "10 Ft. - 3050 MM",
        "12 Ft. - 3660 MM",
        "12 Ft. - 3660 MM",
        "14 Ft. - 4270 MM",
        "16 Ft. - 4880 MM",
        "18 Ft. - 5490 MM",
        "20 Ft. - 6100 MM",
      ],
      "Length of Bed": [
        "1½ Ft. - 2290 MM",
        "9½ Ft. - 2895 MM",
        "9½ Ft. - 2895 MM",
        "12½ Ft. - 3810 MM",
        "15½ Ft. - 4725 MM",
        "15½ Ft. - 4725 MM",
        "18½ Ft. - 5640 MM",
        "18½ Ft. - 5640 MM",
        "21½ Ft. - 6555 MM",
        "24½ Ft. - 7470 MM",
        "28 Ft. - 8535 MM",
        "31 Ft. - 9450 MM",
      ],
      "Length of Table": [
        "5 Ft. - 1525 MM",
        "6½ Ft. - 1980 MM",
        "6¾ Ft. - 2060 MM",
        "8¾ Ft. - 2670 MM",
        "10¾ Ft. - 3280 MM",
        "10¾ Ft. - 3280 MM",
        "12¾ Ft. - 3885 MM",
        "12¾ Ft. - 3885 MM",
        "14¾ Ft. - 4495 MM",
        "17 Ft. - 5185 MM",
        "19 Ft. - 5795 MM",
        "21 Ft. - 6400 MM",
      ],
      "Stroke of Table": [
        "5 Ft. - 1525 MM",
        "6½ Ft. - 1980 MM",
        "6¾ Ft. - 2060 MM",
        "9 Ft. - 2745 MM",
        "11 Ft. - 3355 MM",
        "11 Ft. - 3355 MM",
        "13 Ft. - 3965 MM",
        "13 Ft. - 3965 MM",
        "15 Ft. - 4595 MM",
        "17 Ft. - 5185 MM",
        "19 Ft. - 5795 MM",
        "21 Ft. - 6400 MM",
      ],
      "Width of Table": [
        "25 Inch. - 635 MM",
        "25 Inch. - 635 MM",
        "31 Inch. - 790 MM",
        "31 Inch. - 790 MM",
        "31 Inch. - 790 MM",
        "38 Inch. - 965 MM",
        "31 Inch. - 790 MM",
        "38 Inch. - 965 MM",
        "38 Inch. - 965 MM",
        "38 Inch. - 965 MM",
        "42 Inch. - 1070 MM",
        "42 Inch. - 1070 MM",
      ],
      "Planning Width": [
        "37 Inch. - 940 MM",
        "43 Inch. - 1095 MM",
        "49 Inch. - 1245 MM",
        "49 Inch. - 1245 MM",
        "55 Inch. - 1400 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "73 Inch. - 1855 MM",
        "73 Inch. - 1855 MM",
      ],
      "Height under Cross Rail": [
        "34 Inch. - 865 MM",
        "40 Inch. - 1020 MM",
        "43 Inch. - 1095 MM",
        "49 Inch. - 1245 MM",
        "49 Inch. - 1245 MM",
        "61 Inch. - 1550 MM",
        "49 Inch. - 1245 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "73 Inch. - 1855 MM",
        "73 Inch. - 1955 MM",
      ],
      "Addl. Chal": [
        "9 Inch. - 230 MM",
        "9 Inch. - 230 MM",
        "12 Inch. - 305 MM",
        "12 Inch. - 305 MM",
        "12 Inch. - 305 MM",
        "14 Inch. - 355 MM",
        "12 Inch. - 305 MM",
        "14 Inch. - 355 MM",
        "14 Inch. - 355 MM",
        "14 Inch. - 355 MM",
        "16 Inch. - 405 MM",
        "16 Inch. - 405 MM",
      ],
      "V. Centre": [
        "16½ Inch. - 1 MM",
        "16½ Inch. - 1 MM",
        "20 Inch. - 2 MM",
        "20 Inch. - 2 MM",
        "20 Inch. - 2 MM",
        "25 Inch. - 2 MM",
        "20 Inch. - 2 MM",
        "25 Inch. - 2 MM",
        "25 Inch. - 2 MM",
        "25 Inch. - 2 MM",
        "30 Inch. - 2 MM",
        "30 Inch. - 2 MM",
      ],
      "No. of Tool Post": [1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      "Cutting Speed P.M.": [
        410, 410, 410, 410, 410, 410, 410, 410, 410, 410, 410,
      ],
      "H.P. Required": [2, 2, 3, 3, 5, 5, 5, 5, 7.5, 7.5, 7.5, 7.5],
      "Approx. Wt. in Qtls.": [
        35, 40, 50, 60, 70, 85, 88, 105, 125, 150, 190, 230,
      ],
    },
  },
  {
    id: "15",
    category: "Planner",
    name: "Plano Miller",
    description:
      "The Plano Miller Machine we manufacture is one of the primary choices in the market. Owing to its sturdy construct and high resistance to corrosion, it has a longer functional life. The performance it provides and its speed is highly acclaimed in the market. In addition, we offer this machine to our clients in several specifications and at a market-leading rate.",
    img: "https://navashuindia.com/products/4/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Particulars: [
        "4½ Ft. - 1375 MM",
        "6 Ft. - 1830 MM",
        "6 Ft. - 1830 MM",
        "8 Ft. - 2240 MM",
        "10 Ft. - 3050 MM",
        "10 Ft. - 3050 MM",
        "12 Ft. - 3660 MM",
        "12 Ft. - 3660 MM",
        "14 Ft. - 4270 MM",
        "16 Ft. - 4880 MM",
        "18 Ft. - 5490 MM",
        "20 Ft. - 6100 MM",
      ],
      "Length of Bed": [
        "1½ Ft. - 2290 MM",
        "9½ Ft. - 2895 MM",
        "9½ Ft. - 2895 MM",
        "12½ Ft. - 3810 MM",
        "15½ Ft. - 4725 MM",
        "15½ Ft. - 4725 MM",
        "18½ Ft. - 5640 MM",
        "18½ Ft. - 5640 MM",
        "21½ Ft. - 6555 MM",
        "24½ Ft. - 7470 MM",
        "28 Ft. - 8535 MM",
        "31 Ft. - 9450 MM",
      ],
      "Length of Table": [
        "5 Ft. - 1525 MM",
        "6½ Ft. - 1980 MM",
        "6¾ Ft. - 2060 MM",
        "8¾ Ft. - 2670 MM",
        "10¾ Ft. - 3280 MM",
        "10¾ Ft. - 3280 MM",
        "12¾ Ft. - 3885 MM",
        "12¾ Ft. - 3885 MM",
        "14¾ Ft. - 4495 MM",
        "17 Ft. - 5185 MM",
        "19 Ft. - 5795 MM",
        "21 Ft. - 6400 MM",
      ],
      "Stroke of Table": [
        "5 Ft. - 1525 MM",
        "6½ Ft. - 1980 MM",
        "6¾ Ft. - 2060 MM",
        "9 Ft. - 2745 MM",
        "11 Ft. - 3355 MM",
        "11 Ft. - 3355 MM",
        "13 Ft. - 3965 MM",
        "13 Ft. - 3965 MM",
        "15 Ft. - 4595 MM",
        "17 Ft. - 5185 MM",
        "19 Ft. - 5795 MM",
        "21 Ft. - 6400 MM",
      ],
      "Width of Table": [
        "25 Inch. - 635 MM",
        "25 Inch. - 635 MM",
        "31 Inch. - 790 MM",
        "31 Inch. - 790 MM",
        "31 Inch. - 790 MM",
        "38 Inch. - 965 MM",
        "31 Inch. - 790 MM",
        "38 Inch. - 965 MM",
        "38 Inch. - 965 MM",
        "38 Inch. - 965 MM",
        "42 Inch. - 1070 MM",
        "42 Inch. - 1070 MM",
      ],
      "Planning Width": [
        "37 Inch. - 940 MM",
        "43 Inch. - 1095 MM",
        "49 Inch. - 1245 MM",
        "49 Inch. - 1245 MM",
        "55 Inch. - 1400 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "73 Inch. - 1855 MM",
        "73 Inch. - 1855 MM",
      ],
      "Height under Cross Rail": [
        "34 Inch. - 865 MM",
        "40 Inch. - 1020 MM",
        "43 Inch. - 1095 MM",
        "49 Inch. - 1245 MM",
        "49 Inch. - 1245 MM",
        "61 Inch. - 1550 MM",
        "49 Inch. - 1245 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "61 Inch. - 1550 MM",
        "73 Inch. - 1855 MM",
        "73 Inch. - 1955 MM",
      ],
      "Addl. Chal": [
        "9 Inch. - 230 MM",
        "9 Inch. - 230 MM",
        "12 Inch. - 305 MM",
        "12 Inch. - 305 MM",
        "12 Inch. - 305 MM",
        "14 Inch. - 355 MM",
        "12 Inch. - 305 MM",
        "14 Inch. - 355 MM",
        "14 Inch. - 355 MM",
        "14 Inch. - 355 MM",
        "16 Inch. - 405 MM",
        "16 Inch. - 405 MM",
      ],
      "V. Centre": [
        "16½ Inch. - 1 MM",
        "16½ Inch. - 1 MM",
        "20 Inch. - 2 MM",
        "20 Inch. - 2 MM",
        "20 Inch. - 2 MM",
        "25 Inch. - 2 MM",
        "20 Inch. - 2 MM",
        "25 Inch. - 2 MM",
        "25 Inch. - 2 MM",
        "25 Inch. - 2 MM",
        "30 Inch. - 2 MM",
        "30 Inch. - 2 MM",
      ],
      "No. of Tool Post": [1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      "Cutting Speed P.M.": [
        410, 410, 410, 410, 410, 410, 410, 410, 410, 410, 410,
      ],
      "H.P. Required": [2, 2, 3, 3, 5, 5, 5, 5, 7.5, 7.5, 7.5, 7.5],
      "Approx. Wt. in Qtls.": [
        35, 40, 50, 60, 70, 85, 88, 105, 125, 150, 190, 230,
      ],
    },
  },
  {
    id: "16",
    category: "Slotting Machine",
    name: "Slotter Machine",
    description:
      "Slotter Machine is used for trimming, creasing, slotting and corner cutting of sheets simultaneously. It has four creasing heads, four trimming heads, three slotting heads and one corner cutting head. Chain feeding arrangement is provided with two side gauges to feed the board. The creaser & slatter heads are easily adjustable to the required size.",
    img: "https://navashuindia.com/products/5/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Model: [1, 2, 3, 4],
      "Working Strol": [126, 152, 252, 302],
      "Length of Ram": [202, 554, 680, 730],
      "Vertical Adj. of Ram": [88, 152, 202, 265],
      "Centre of Cutting Tools to Col.": [164, 252, 378, 403],
      "Working Surface of Table": [202, 278, 352, 605],
    },
  },
  {
    id: "17",
    category: "Slotting Machine",
    name: "Slotter Cum Milling",
    description:
      "We are one of the trusted names in the industry engaged in manufacturing, supplying and exporting high-tech Slotter Cum Milling Machines to the customers. These products capably serve the purpose of Slotter cores or holes and also helps in milling them too. Mentioned below are the noted attributes of these products.",
    img: "https://navashuindia.com/products/5/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Model: [1, 2, 3, 4],
      "Working Strol": [126, 152, 252, 302],
      "Length of Ram": [202, 554, 680, 730],
      "Vertical Adj. of Ram": [88, 152, 202, 265],
      "Centre of Cutting Tools to Col.": [164, 252, 378, 403],
      "Working Surface of Table": [202, 278, 352, 605],
    },
  },
  {
    id: "18",
    category: "Milling Machine",
    name: "Vertical/Horizontal Milling Machine",
    description:
      "We are an eminent trader, supplier, exporter and importer of specifically engineered Vertical/Horizontal Milling Machine that can be availed at affordable rates. Widely acknowledged for its consistent performance, tough construction and longer operational life, the batch is quite popular. Our clients can obtain these machines in multiple specifications.",
    img: "https://navashuindia.com/products/6/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Model: [1, "1½", 2, 3, 4],
      "Face of Body": ["9-230", "10-254", "11-280", "12-300", "15-381"],
      "Working Surface": [
        "9x41 - 230x1015",
        "10x48 - 254x1220",
        "11x51 - 280x1295",
        "12x54 - 300x1371",
        "13x80 - 330x1524",
      ],
      Cross: ["6-150", "7-177", "10-254", "12-304", "14-355"],
      Vertical: ["12-304", "15-380", "18-457", "24-609", "30-762"],
      "Table Rotation Side to Side": [
        "45°-0-45°",
        "45°-0-45°",
        "45°-0-45°",
        "45°-0-45°",
        "45°-0-45°",
      ],
      "Standard Arbour": ["1-25.4", "1-25.4", "1-25.4", "1-25.4", "1-25.4"],
      "Taper of Spindle": ["ISO-40", "ISO-40", "ISO-40", "ISO-40", "ISO-40"],
      "No. of spindle speed": [9, 9, 9, 9, 9],
      "Range of spindle R.P.M": [
        "45-750",
        "45-750",
        "45-750",
        "45-750",
        "45-750",
      ],
      "No. of Feed (Longitudinal)": [
        "3 S-F-M.",
        "3 S-F-M.",
        "3 S-F-M.",
        "3 S-F-M.",
        "3 S-F-M.",
      ],
      "Motor Required H.P. & R.P.M.": [
        "2-1440",
        "2-1440",
        "3-1440",
        "2-1440",
        "51440",
      ],
    },
  },
  {
    id: "19",
    category: "Milling Machine",
    name: "All Geared Milling Machine",
    description:
      "We offer All Geared Milling Machine with standard quality with durability at very Economic rates. They are designed precisely by the expert designers keeping in mind the needs of the client and international standards. Any kind of slotting can be done by these machines.",
    img: "https://navashuindia.com/products/6/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Model: [1, "1½", 2, 3, 4],
      "Face of Body": ["9-230", "10-254", "11-280", "12-300", "15-381"],
      "Working Surface": [
        "9x41 - 230x1015",
        "10x48 - 254x1220",
        "11x51 - 280x1295",
        "12x54 - 300x1371",
        "13x80 - 330x1524",
      ],
      Cross: ["6-150", "7-177", "10-254", "12-304", "14-355"],
      Vertical: ["12-304", "15-380", "18-457", "24-609", "30-762"],
      "Table Rotation Side to Side": [
        "45°-0-45°",
        "45°-0-45°",
        "45°-0-45°",
        "45°-0-45°",
        "45°-0-45°",
      ],
      "Standard Arbour": ["1-25.4", "1-25.4", "1-25.4", "1-25.4", "1-25.4"],
      "Taper of Spindle": ["ISO-40", "ISO-40", "ISO-40", "ISO-40", "ISO-40"],
      "No. of spindle speed": [9, 9, 9, 9, 9],
      "Range of spindle R.P.M": [
        "45-750",
        "45-750",
        "45-750",
        "45-750",
        "45-750",
      ],
      "No. of Feed (Longitudinal)": [
        "3 S-F-M.",
        "3 S-F-M.",
        "3 S-F-M.",
        "3 S-F-M.",
        "3 S-F-M.",
      ],
      "Motor Required H.P. & R.P.M.": [
        "2-1440",
        "2-1440",
        "3-1440",
        "2-1440",
        "51440",
      ],
    },
  },
  {
    id: "20",
    category: "Power Press",
    name: "C-Type",
    description:
      "In order to fulfill the ever changing demands of our clients, we have been engaged in offering a quality range of C Type Mechanical Power Presses. Manufactured using optimum quality raw material and advanced technology, the presses offered by us are known for their high performance and easy installation.",
    img: "https://navashuindia.com/products/7/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Model No.": [
        "NMT-3T",
        "NMT-5T",
        "NMT-10T",
        "NMT-20T",
        "NMT-30T",
        "NMT-50T",
        "NMT-150T",
        "NMT-200T",
        "NMT-250T",
      ],
      Tonnage: ["3", "5", "10", "20", "30", "50", "150", "200", "250"],
      "Crank Shaft Diameter": [
        "38",
        "51",
        "60",
        "76",
        "83",
        "105",
        "152",
        "158",
        "165",
      ],
      "Stroke Length": [
        "25",
        "25",
        "25",
        "51",
        "63",
        "76",
        "152",
        "158",
        "165",
      ],
      "Stroke Adjustment": [
        "6-25",
        "6-25",
        "6-25",
        "13-51",
        "13-63",
        "13-76",
        "13-152",
        "13-158",
        "13-165",
      ],
      "Bed Size": [
        "203x152",
        "205x178",
        "381x255",
        "508x305",
        "558x356",
        "750x520",
        "965x685",
        "1016x738",
        "1016x778",
      ],
      "Distance Bed TO Ram": [127, 153, 205, 280, 292, 406, 495, 519, 544],
      "Hole in Ram": [19, 25, 25, 32, 38, 38, 57, 63, 63],
      "Hole in Bed": [51, 63, 76, 83, 89, 127, 191, 216, 229],
      "Fly Wheel Diameter": [330, 405, 435, 550, 660, 810, 1118, 1150, 1320],
      "Motor H.P. Required": [0.5, 0.5, 1, 2, 3, 5, 15, 20, 25],
      Length: [625, 940, 1100, 1240, 1325, 1575, 1970, 2190, 2340],
      Width: [610, 915, 1070, 1220, 1300, 1510, 1900, 2050, 2230],
      Height: [1016, 1370, 1525, 1830, 1920, 2420, 3200, 3250, 3300],
      "Weight Approx Kgs.": [
        150, 275, 525, 1020, 1350, 2750, 6600, 9000, 11500,
      ],
    },
  },
  {
    id: "21",
    category: "Power Press",
    name: "H-Type",
    description:
      "We are the Leading supplier of H type Mechanical Power Press. Mechanical Power Presses are categorized in 'H' frame versions from 10 tons to 250 tons capacity in most economical & conventional type of construction with several std./optional features such as manual/auto lubrication systems, knockouts electronics hand safety, Single/Double or Un-geared versions as also Pneumatic feeders/ straightness and de-coilers for automation purpose.",
    img: "https://navashuindia.com/products/7/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Model No.": [
        "NMT-3T",
        "NMT-5T",
        "NMT-10T",
        "NMT-20T",
        "NMT-30T",
        "NMT-50T",
        "NMT-150T",
        "NMT-200T",
        "NMT-250T",
      ],
      Tonnage: ["3", "5", "10", "20", "30", "50", "150", "200", "250"],
      "Crank Shaft Diameter": [
        "38",
        "51",
        "60",
        "76",
        "83",
        "105",
        "152",
        "158",
        "165",
      ],
      "Stroke Length": [
        "25",
        "25",
        "25",
        "51",
        "63",
        "76",
        "152",
        "158",
        "165",
      ],
      "Stroke Adjustment": [
        "6-25",
        "6-25",
        "6-25",
        "13-51",
        "13-63",
        "13-76",
        "13-152",
        "13-158",
        "13-165",
      ],
      "Bed Size": [
        "203x152",
        "205x178",
        "381x255",
        "508x305",
        "558x356",
        "750x520",
        "965x685",
        "1016x738",
        "1016x778",
      ],
      "Distance Bed TO Ram": [127, 153, 205, 280, 292, 406, 495, 519, 544],
      "Hole in Ram": [19, 25, 25, 32, 38, 38, 57, 63, 63],
      "Hole in Bed": [51, 63, 76, 83, 89, 127, 191, 216, 229],
      "Fly Wheel Diameter": [330, 405, 435, 550, 660, 810, 1118, 1150, 1320],
      "Motor H.P. Required": [0.5, 0.5, 1, 2, 3, 5, 15, 20, 25],
      Length: [625, 940, 1100, 1240, 1325, 1575, 1970, 2190, 2340],
      Width: [610, 915, 1070, 1220, 1300, 1510, 1900, 2050, 2230],
      Height: [1016, 1370, 1525, 1830, 1920, 2420, 3200, 3250, 3300],
      "Weight Approx Kgs.": [
        150, 275, 525, 1020, 1350, 2750, 6600, 9000, 11500,
      ],
    },
  },
  {
    id: "22",
    category: "Power Press",
    name: "Hydraulic Press",
    description:
      "Hydraulic Press with the experience of years and highly dedicated professional we are able to manufacture Hydraulic Press. These products are checked by our quality controllers on diverse parameters to cater the global quality norms. Our products are also available at budget friendly rates to the customers. Our range is extensively used in Automotive, Kitchen appliances, electronics industries and many more.",
    img: "https://navashuindia.com/products/7/3.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Model No.": [
        "NMT-3T",
        "NMT-5T",
        "NMT-10T",
        "NMT-20T",
        "NMT-30T",
        "NMT-50T",
        "NMT-150T",
        "NMT-200T",
        "NMT-250T",
      ],
      Tonnage: ["3", "5", "10", "20", "30", "50", "150", "200", "250"],
      "Crank Shaft Diameter": [
        "38",
        "51",
        "60",
        "76",
        "83",
        "105",
        "152",
        "158",
        "165",
      ],
      "Stroke Length": [
        "25",
        "25",
        "25",
        "51",
        "63",
        "76",
        "152",
        "158",
        "165",
      ],
      "Stroke Adjustment": [
        "6-25",
        "6-25",
        "6-25",
        "13-51",
        "13-63",
        "13-76",
        "13-152",
        "13-158",
        "13-165",
      ],
      "Bed Size": [
        "203x152",
        "205x178",
        "381x255",
        "508x305",
        "558x356",
        "750x520",
        "965x685",
        "1016x738",
        "1016x778",
      ],
      "Distance Bed TO Ram": [127, 153, 205, 280, 292, 406, 495, 519, 544],
      "Hole in Ram": [19, 25, 25, 32, 38, 38, 57, 63, 63],
      "Hole in Bed": [51, 63, 76, 83, 89, 127, 191, 216, 229],
      "Fly Wheel Diameter": [330, 405, 435, 550, 660, 810, 1118, 1150, 1320],
      "Motor H.P. Required": [0.5, 0.5, 1, 2, 3, 5, 15, 20, 25],
      Length: [625, 940, 1100, 1240, 1325, 1575, 1970, 2190, 2340],
      Width: [610, 915, 1070, 1220, 1300, 1510, 1900, 2050, 2230],
      Height: [1016, 1370, 1525, 1830, 1920, 2420, 3200, 3250, 3300],
      "Weight Approx Kgs.": [
        150, 275, 525, 1020, 1350, 2750, 6600, 9000, 11500,
      ],
    },
  },
  {
    id: "23",
    category: "Boring Machine",
    name: "Horizontal Boring",
    description:
      "Being reckoned names in this industry, we are offering NAMT Horizontal Boring to our precious patrons. Our offered products are generally requested for their reliable execution and sturdy nature. These are prepared utilizing the high review parts and contemporary",
    img: "https://navashuindia.com/products/8/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Model No.": [
        "NAMT80",
        "NAMT100",
        "NAMT110",
        "NAMT125",
        "NAMT130",
        "NAMT150",
      ],
      "Alloy Steel Work Spindle Hardened & Ground-Diameter": [
        80, 100, 110, 125, 130, 150,
      ],
      "Morse Taper Number": [5, 6, 6, 6, 6, 6],
      "Max. Longitudinal Movements of Working Spindle": [
        510, 510, 510, 600, 600, 650,
      ],
      "Number of Spindle Speeds": [9, 9, 9, 9, 9, 9],
      "Range of Spindle Speeds R.P.M.": [
        "15 to 300",
        "15 to 250",
        "15 to 250",
        "15 to 250",
        "15 to 250",
        "12 to 250",
      ],
      "Longitudinal Work Spindle Feeds Nos.": [9, 9, 9, 9, 9, 9],
      "Max. Height of the Spindle Axis from Table Surface": [
        925, 1000, 1100, 1200, 1300, 1500,
      ],
      "Min. Height of the Spindle Axis from Table Surface": [
        25, 30, 30, 30, 30, 30,
      ],
      "Dimensions of the Rotary Table": [
        "900*1105",
        "1030*1375",
        "1100*1450",
        "1220*1675",
        "1320*1775",
        "1500*2000",
      ],
      "Longitudinal table Traverse": [1625, 1775, 1925, 2050, 2800, 2950],
      "Long. Table Traverse Feeds (Including one Rapid)": [
        10, 10, 10, 10, 10, 10,
      ],
      "Transversal Table Travel Feeds (Including one Rapid)": [
        10, 10, 10, 10, 10, 10,
      ],
      "Vertical Head Stock Travel Feeds (Including one Rapid)": [
        4, 4, 4, 10, 10, 10,
      ],
      "Rotary Table Automatic Feeds (Including one Rapid)": [
        10, 10, 10, 10, 10, 10,
      ],
      "Max. Cross Movement of the Table": [1000, 1100, 1300, 1500, 2000, 2000],
      "Max. Distance between Stay Bearing & Facing Head": [
        2400, 2690, 2800, 2950, 3600, 3950,
      ],
      "Facing Head Diameter": [505, 555, 600, 650, 650, 700],
      "Weight Approx. in Kgs.": [8500, 9500, 11000, 16000, 17000, 19000],
    },
  },
  {
    id: "24",
    category: "Boring Machine",
    name: "Cylindrical Re-boring",
    description:
      "Our organization with years of industry experience and expertise is offering Cylindrical Re-Boring Machine to our clients. This boring machine is able to operate in excellent manner and is designed to bear effects of high temperature and pressure quite easily. This machine finds application in boring and is reliable too. It is easily installed and is proven for its performance. This is easily carried from one location to another. This machine is provided with 4 nos expandable and contract able guiders. It moves on special scrolls above cutting tool.",
    img: "https://navashuindia.com/products/8/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Model No.": [
        "NAMT80",
        "NAMT100",
        "NAMT110",
        "NAMT125",
        "NAMT130",
        "NAMT150",
      ],
      "Alloy Steel Work Spindle Hardened & Ground-Diameter": [
        80, 100, 110, 125, 130, 150,
      ],
      "Morse Taper Number": [5, 6, 6, 6, 6, 6],
      "Max. Longitudinal Movements of Working Spindle": [
        510, 510, 510, 600, 600, 650,
      ],
      "Number of Spindle Speeds": [9, 9, 9, 9, 9, 9],
      "Range of Spindle Speeds R.P.M.": [
        "15 to 300",
        "15 to 250",
        "15 to 250",
        "15 to 250",
        "15 to 250",
        "12 to 250",
      ],
      "Longitudinal Work Spindle Feeds Nos.": [9, 9, 9, 9, 9, 9],
      "Max. Height of the Spindle Axis from Table Surface": [
        925, 1000, 1100, 1200, 1300, 1500,
      ],
      "Min. Height of the Spindle Axis from Table Surface": [
        25, 30, 30, 30, 30, 30,
      ],
      "Dimensions of the Rotary Table": [
        "900*1105",
        "1030*1375",
        "1100*1450",
        "1220*1675",
        "1320*1775",
        "1500*2000",
      ],
      "Longitudinal table Traverse": [1625, 1775, 1925, 2050, 2800, 2950],
      "Long. Table Traverse Feeds (Including one Rapid)": [
        10, 10, 10, 10, 10, 10,
      ],
      "Transversal Table Travel Feeds (Including one Rapid)": [
        10, 10, 10, 10, 10, 10,
      ],
      "Vertical Head Stock Travel Feeds (Including one Rapid)": [
        4, 4, 4, 10, 10, 10,
      ],
      "Rotary Table Automatic Feeds (Including one Rapid)": [
        10, 10, 10, 10, 10, 10,
      ],
      "Max. Cross Movement of the Table": [1000, 1100, 1300, 1500, 2000, 2000],
      "Max. Distance between Stay Bearing & Facing Head": [
        2400, 2690, 2800, 2950, 3600, 3950,
      ],
      "Facing Head Diameter": [505, 555, 600, 650, 650, 700],
      "Weight Approx. in Kgs.": [8500, 9500, 11000, 16000, 17000, 19000],
    },
  },
  {
    id: "25",
    category: "Sheet Bending Machine",
    name: "Hydraulic Sheet Bending",
    description:
      "Owing to its quality and competitive pricing, this Hydraulic Sheet Bending Machine is highly preferred. The offered machine finds its application in several industries for the purpose of reinforcing and bending bars to various shapes and forms. The machine's easy installation and operation minimizes the using of labors. The offered product is highly durable and economical as well.",
    img: "https://navashuindia.com/products/9/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "26",
    category: "Sheet Bending Machine",
    name: "Mechanical Sheet Bending",
    description:
      "Mechanical Sheet Bending Machine are designed taking into consideration all severe service conditions of sheet metal shop. The machine are heavy duty robust in design and acclaimed recognition in requiring high accuracy productivity & efficiency. Frame in made of steel fabricated construction of interlocked robust design. Machines are constructed with safeguards against undesired deflections and breakage. The frame distributes the stresses , supporting the bed directly on the frame.",
    img: "https://navashuindia.com/products/9/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "27",
    category: "Shearing Machine",
    name: "Hydraulic Shearing Machine",
    description:
      "We are among the reputed organizations, highly engaged in offering an optimum quality range of Hydraulic Shearing Machine. The stable robust, all welded steel frame from ultra sonically tested plates are of top quality. The top beam is guided by three point roller with replaceable hardened liners for least wear. In this machine, Raw and table designed with special inclined member and box type structure for maximum strength.",
    img: "https://navashuindia.com/products/10/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Technical Data": ["NMT-1", "NMT-2", "NMT-3", "NMT-4"],
      "Shearing Length (mm)": [1250, 2500, 3000, 4000],
      "Shearing Capacity (mm)": [
        "6/8/10/12/16",
        "6/8/10/12/16",
        "6/8/10/12/16",
        "6/8/10/12/16",
      ],
      "Depth of gap in Frame (mm)": [175, 175, 175, 175],
      "Max. back gauge adjustment (mm)": [
        "25-750",
        "25-1000",
        "25-1000",
        "25-1000",
      ],
      "Size of blades (mm)": [
        "1270x75/100x25",
        "1270x75/100x25",
        "1524x75/100x25",
        "1350x75/100x 25",
      ],
      "Length of cut in one stroke (mm)": [1250, 2500, 3000, 4000],
      "No. of Strokes per minute (mm)": [30, 28, 26, 26],
      "Power Required (HP/1440 x rmp)": [
        "7.5/10/15/20/25",
        "10/15/20/25/30",
        "15/20/25/30/35",
        "15/20/25/30/40",
      ],
      "Floor Area (app.) L X W X H (mm)": [
        "2700 x 2000 x 2200",
        "3200 x 2000 x 2200",
        "3700 x 2100 x 2300",
        "5000 x 2100 x 2300",
      ],
    },
  },
  {
    id: "28",
    category: "Shearing Machine",
    name: "Mechanical Shearing Machine",
    description:
      "We have carved a niche amongst the most dominant names in this domain, providing of high quality of Mechanical Shearing Machine. We are offering our clients, an excellent quality range of Shearing Machines. These Shear Machines are widely known for its durability and quality. These high quality Shearing Machines are made by using very high quality raw material which ensures hassle free work performance at its user end.",
    img: "https://navashuindia.com/products/10/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Technical Data": ["NMT-1", "NMT-2", "NMT-3", "NMT-4"],
      "Shearing Length (mm)": [1250, 2500, 3000, 4000],
      "Shearing Capacity (mm)": [
        "6/8/10/12/16",
        "6/8/10/12/16",
        "6/8/10/12/16",
        "6/8/10/12/16",
      ],
      "Depth of gap in Frame (mm)": [175, 175, 175, 175],
      "Max. back gauge adjustment (mm)": [
        "25-750",
        "25-1000",
        "25-1000",
        "25-1000",
      ],
      "Size of blades (mm)": [
        "1270x75/100x25",
        "1270x75/100x25",
        "1524x75/100x25",
        "1350x75/100x 25",
      ],
      "Length of cut in one stroke (mm)": [1250, 2500, 3000, 4000],
      "No. of Strokes per minute (mm)": [30, 28, 26, 26],
      "Power Required (HP/1440 x rmp)": [
        "7.5/10/15/20/25",
        "10/15/20/25/30",
        "15/20/25/30/35",
        "15/20/25/30/40",
      ],
      "Floor Area (app.) L X W X H (mm)": [
        "2700 x 2000 x 2200",
        "3200 x 2000 x 2200",
        "3700 x 2100 x 2300",
        "5000 x 2100 x 2300",
      ],
    },
  },
  {
    id: "29",
    category: "Hydraulic Hexa Machine",
    name: "Hydraulic Hexa Machine",
    description:
      "Owing to our rich experience of the industry, we are engaged in manufacturing, supplying and exporting Hydraulic Hacksaw Machine. Extensively used for several cutting applications, these hacksaws are manufactured by using optimum quality material and following the norms laid by the industry. Highly appreciated for durability, low maintenance and robustness, these hacksaws are available in various specifications as well as in customized range, as per the needs of the clients. The details of the product range is mentioned below:",
    img: "https://navashuindia.com/products/11/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Size: [7, 8, 10, 12, 14, 16, 18, 20],
      Stroke: [5.5, 5.5, 5.5, 5.5, 5.5, 5.5, 5.5, 5.5],
      "No of Stroke per minute": [110, 110, 95, 95, 95, 80, 70, 70],
      "Power reqd. (in H.P.)": [1, 1, 1.5, 2, 2, 3, 3, 5],
      "Blade size": [14, 16, 18, 20, 22, 24, 28, 30],
      "Round Bar Cap.": [5, 6, 8, 10, 11, 12, 14, 15],
    },
  },
  {
    id: "30",
    category: "Grinder",
    name: "Surface Grinder",
    description:
      "We are a Manufacturer , supplier & Exporter of Surface Grinder Machine, Surface Grinder Machine which we offer is designed for efficiant and precision Grinder of metals, Surface Grinder Machine is fast , easy to use and does not cost much . we offer Surface Grinder Machinein different specifications cater diverse demand of the clients.",
    img: "https://navashuindia.com/products/12/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Working Surface of Table": ["325 X 155", "525 X 205", "605 X 225"],
      "Grinding Capacity": ["300 X 150", "500 X 200", "600 X 250"],
      "Longitudinal Trav. of Table": [325, 525, 610],
      "Cross Traverse": [170, 215, 265],
      "Vertical Traverse": [200, 250, 250],
      "Grinding Wheel Dia & Width": ["150 X 12", "150 X 12", "175 X 12"],
      "Spindle Speed (RPM)": [2800, 2800, 280],
      "Electric Motor for Grinding Wheel": [1 / 2, 1, 1],
      "Motor RPM Required": [2800, 2800, 2800],
    },
  },
  {
    id: "31",
    category: "Grinder",
    name: "Tool Grinder",
    description:
      "Tool Grinder offered provides for superior functional support and can be made available in a variety of configuration options to pick from. Finding suitability for use in tool room and production lines, these can be made available individually or in kit form with grinding wheels, spindles, mounted points. Some of its features include high accuracy in performance; suitable for handling internal and external grinding operations; all-around versatility; successfully accommodate wide range of tool room needs",
    img: "https://navashuindia.com/products/12/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "32",
    category: "Grinder",
    name: "Tool Post Grinder",
    description:
      "Tool Post Grinders are specially suited for use on Lathe for cylindrical internal and external grinding. Other Operation like; radius grinding,face grinding surface grinding and many more such operations could be performedeconomically by developing proper set-ups and mountings on lathe, Shapers and Planer Machines. The spindle is made of alloy steel, hardened and precision ground. The Spindle is supported on four Precision Angular Contact Bearings to take radial and axial loads.",
    img: "https://navashuindia.com/products/12/3.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "33",
    category: "Grinder",
    name: "Cylinderical Grinder",
    description:
      "We hold specialization in offering a wide range of Cylinderical Grinder Machine to the clients. These products are manufactured using optimum quality raw material and latest technology machines. These are provided with an abrasive wheel as the cutting device which ensure fine and light cuts. We also offer customization of these grinders as per the details provided by the clients. Further, we manufacture these products in line with the global quality standards and norms.",
    img: "https://navashuindia.com/products/12/4.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "34",
    category: "Grinder",
    name: "Tool & Cutter Grinder",
    description:
      "We are engaged in supplying, exporting and trading broad range of Tool & Cutter Grinder Machines. Our products are source from professional manufacturers of the market. We offer products to our patrons as per their requirements and in the committed time frame. These products are widely used for their perfect operations.",
    img: "https://navashuindia.com/products/12/5.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "35",
    category: "Metal Cutting Machine",
    name: "Angle Cutter",
    description:
      "This Angle Cutter is mainly used for cutting angle process of angle steel, in the iron tower of the power transmissions and communications. Angle Cutters are acknowledged for their optimum performance, reliability, low maintenance cost and longer functional life.",
    img: "https://navashuindia.com/products/13/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "36",
    category: "Metal Cutting Machine",
    name: "Sheet Roller",
    description:
      "Sheet Roller Machines offered find application in sheet and plate rolling processes and can handle sheet thickness of 20 mm with minimum diameter of 200. Made available with electrically operated & drive gear box based functioning, we offer these systems with fabricated type and casting type construction options. The machine provides suitable fabrication support to automotive, engineering and other manufacturing industries.",
    img: "https://navashuindia.com/products/13/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "37",
    category: "Metal Cutting Machine",
    name: "Metal Cutting Bandsaw",
    description:
      "The range of Metal Cutting Bandsaw Machine are fabricated using high grade raw material and latest technology. We make sure to deliver these products at the clients end within the stipulated time frame. We make sure to source the raw material for manufacturing these Saws from some of the renowned market vendors.",
    img: "https://navashuindia.com/products/13/3.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "38",
    category: "Metal Cutting Machine",
    name: "Press Break",
    description:
      "With the aid of modern machinery and technology, we have been able to manufacture and supply Press Brake Machine. Our offered machine is widely used in various industries. By utilizing cutting-edge technology and well-tested components in manufacturing, we have been able to manufacture a much-coveted touch of uniqueness to the entire range. Also, we perform various tests on the entire range so as avoid any possibility of flaw. Increasing popularity of our offered machine in the market remains directly attributable to its enhanced service life and hassle free performance.",
    img: "https://navashuindia.com/products/13/4.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "39",
    category: "Metal Cutting Machine",
    name: "Fly Press",
    description:
      "Being one of the celebrated traders, retailers and suppliers, we bring forth Fly Press that is manufactured in adherence to the set industry standards, at vendors' end. These fly presses have their screw shaft driven by a flywheel or a pair of flyweights, at the ends of a bar. In the proffered fly presses, the wheel can either be driven by a motor using a friction coupling or cranked by hand.",
    img: "https://navashuindia.com/products/13/5.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {},
  },
  {
    id: "40",
    category: "Wood Working Machine",
    name: "Multi-purpose Wood Working Machine",
    description:
      'Owing to the presence of expert engineers, we have been able to engineer a wide range of Multi Purpose Woodworking Machines. These machines are available in different models but the 13" and 10" models are mostly demanded by the clients. We are an ISO certified organization and follow strict quality standards. We ensure clients for durability, reliability, dimensional accuracy and consistent performance of our machines.',
    img: "https://navashuindia.com/products/14/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Size: [12, 18, 24],
      "Max. length of jainter planer": [54, 68, 73],
      "Width of table": [12, 18, 24],
      "Over all height floor to that": [38, 40, 40],
      "Height from floor to table": [34, 34, 34],
      "Cutter block dia": ["4", "4-1/2", "5"],
      "Cutter block rpm": [4200, 4200, 4200],
      "Circular saw table": ["13*23", "13*23", "16*27"],
      "Boring capacity": ["1/2", "5/8", "3/4"],
      "H.P. required": [2, 3, 5],
    },
  },
  {
    id: "41",
    category: "Wood Working Machine",
    name: "Bandsaw Machine",
    description:
      "We are among the acclaimed names in the industry, Bandsaw Machine for our priceless customers. The offered machines are highly acknowledged for their power efficiency and are characterized by a sturdy construction. Premium quality mild steel is obtained from credible vendors for manufacturing these band saw machines. Owing to their user friendliness and minimal maintenance requirements, these machines are highly appreciated by the customers.",
    img: "https://navashuindia.com/products/14/2.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      Size: [12, 18, 24],
      "Max. length of jainter planer": [54, 68, 73],
      "Width of table": [12, 18, 24],
      "Over all height floor to that": [38, 40, 40],
      "Height from floor to table": [34, 34, 34],
      "Cutter block dia": ["4", "4-1/2", "5"],
      "Cutter block rpm": [4200, 4200, 4200],
      "Circular saw table": ["13*23", "13*23", "16*27"],
      "Boring capacity": ["1/2", "5/8", "3/4"],
      "H.P. required": [2, 3, 5],
    },
  },
  {
    id: "42",
    category: "RH/LH combination reamer available.",
    name: "Landis/Pipe Threading Machine",
    description:
      "We made our mark in the market by offering an exclusive range of Pipe Threading Machines. Client stands on paramount importance to us, thus we manufacture the range as per their requirements. Our supreme quality range has earned us the accolades of clients from national and international market. Excellent functionality and durability of the range has made it the first preference of the customers.",
    img: "https://navashuindia.com/products/15/1.jpg",
    stars: 4,
    ratings: 400,
    minPrice: 500000,
    maxPrice: 5000000,
    specifications: {
      "Pipe Capacity":
        '/8"-2" (3mm-50mm) 2 1/2"-4" (62mm-100mm), 4"-6" (100mm-150mm) with 141 and 161 geared threaders.',
      "Bolt Capacity": '1/4"-2" (6mm-50mm).',
      Switches: "Forward/off/reverse and integral foot switch.",
      Chuck: "Speed Chuck™ with three replaceable rocker-action jaw inserts.",
      "Rear Centering Device": "Cam-action, turns with chuck.",
      Cutters:
        'Self-centering, full-floating. Two models available: Standard Model 820 Wheel-Type Cutter, pipe capacity 1/8"-2" (3mm-50mm), bolt capacity 1/4"-1" (6mm-25mm). Optional Model 821 Blade-Type cutter, pipe capacity 1/2"-2" (12mm-50mm). See ordering chart.',
      Reamer: '5-flute cone, RH, 1/8"-2" (3mm-50mm).',
      "Oil Pump": "Self-priming gerotor oil pump.",
      Reamers: "RH/LH combination reamer available.",
    },
  },
];

export const categories = [
  "Lathe Machine",
  "Shaper",
  "Radial Drilling Machine",
  "Planner",
  "Slotting Machine",
  "Milling Machine",
  "Power Press",
  "Boring Machine",
  "Sheet Bending Machine",
  "Shearing Machine",
  "Hydraulic Hexa Machine",
  "Grinder",
  "Metal Cutting Machine",
  "Wood Working Machine",
  "Landis/Pipe Threading Machine",
];

export const popularProducts = [
  products[0],
  products[1],
  products[2],
  products[3],
];

export const bestProducts = [
  products[4],
  products[5],
  products[6],
  products[7],
];

export const featuredProducts = [
  products[7],
  products[5],
  products[4],
  products[0],
];
